import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  ContentfulImplicitAssociationAssessment,
  ContentfulLikertAssessment,
  ContentfulMultipleChoiceQuestion,
  ContentfulDateOfBirthPicker,
  ContentfulLanguagePicker,
  ContentfulCountryPicker,
  ContentfulSurveySectionOptionalOverview,
} from "types/graphql-types"
import { Likert, MultipleChoice } from "src/components/assessments"
import DateOfBirthPicker from "src/components/assessments/dob-picker"
import ImplicitBiasSurvey from "src/components/surveys/implicitBias/implicit-bias-survey"
import { RouteComponentProps } from "@reach/router"
import { ImplicitBiasInput } from "../../../external-api/service-api"
import LanguagePicker from "src/components/assessments/language-picker"
import CountryPicker from "src/components/assessments/country-picker"
import { headerTypes, IHeaderProps } from "src/components/layout/custom-layout"
import { ImplicitBiasOptional } from "src/components/surveys/implicitBias"
import { useSelector } from "react-redux"
import { getLanguage } from "src/state/features/ui/uiSelector"

interface IProps extends RouteComponentProps {
  surveyModuleId: string | undefined
  handleNextStep: () => void
  handleImplicitBiasInput: (answer: ImplicitBiasInput) => void
  handleSkipPracticeSection: () => void
}

export type SurveyAssessmentType =
  | ({ __typename: "ContentfulLikertAssessment" } & ContentfulLikertAssessment)
  | ({
      __typename: "ContentfulMultipleChoiceQuestion"
    } & ContentfulMultipleChoiceQuestion)
  | ({
      __typename: "ContentfulImplicitAssociationAssessment"
    } & ContentfulImplicitAssociationAssessment)
  | ({
      __typename: "ContentfulDateOfBirthPicker"
    } & ContentfulDateOfBirthPicker)
  | ({ __typename: "ContentfulLanguagePicker" } & ContentfulLanguagePicker)
  | ({ __typename: "ContentfulCountryPicker" } & ContentfulCountryPicker)

const SurveyModule = ({
  surveyModuleId,
  handleNextStep,
  handleImplicitBiasInput,
  handleSkipPracticeSection,
}: IProps) => {
  const lang = useSelector(getLanguage)
  const fullData = useStaticQuery<any>(graphql`
    query SurveyModuleContent {
      allContentfulSurveySection {
        edges {
          node {
            node_locale
            id
            title
            optional
            name
            slug
            optionalOverview {
              raw
            }
            content {
              ... on ContentfulDateOfBirthPicker {
                ...dateOfBirthPickerFragment
              }
              ... on ContentfulLanguagePicker {
                ...languagePickerFragment
              }
              ... on ContentfulCountryPicker {
                ...countryPickerFragment
              }
              ... on ContentfulImplicitAssociationAssessment {
                ...implicitBiasAssessmentFragment
              }
              ... on ContentfulLikertAssessment {
                ...likertFragment
              }
              ... on ContentfulMultipleChoiceQuestion {
                ...multipleChoiceFragment
              }
            }
          }
        }
      }
    }
  `).allContentfulSurveySection.edges
  const data =
    fullData.find(
      (x: any) =>
        x?.node?.node_locale === lang && x?.node?.id === surveyModuleId
    )?.node || fullData.find((x: any) => x?.node?.id === surveyModuleId)?.node

  const [surveyStep, setsurveyStep] = useState(0)

  const [showOptionalSection, setShowOptionalSection] = useState(
    data?.optional ? true : false
  )

  useEffect(() => {
    setShowOptionalSection(data?.optional!)
  }, [data?.optional])

  function handleNextModuleContent() {
    if (data && data.content && surveyStep < data.content.length - 1) {
      setsurveyStep(surveyStep + 1)
    } else {
      handleNextStep()
      setsurveyStep(0)
    }
  }

  function handleReject() {
    setShowOptionalSection(false)
    handleNextStep()
  }
  const headerProps: IHeaderProps = {
    type: headerTypes.contentPagination,
    title: data?.title || "",
    step: surveyStep + 1,
    allSteps: data?.content?.length!,
  }

  function renderSurveyContent() {
    if (data && data.content) {
      const assessmentStep = data?.content[surveyStep] as SurveyAssessmentType
      switch (assessmentStep?.__typename) {
        case "ContentfulDateOfBirthPicker": {
          return (
            <DateOfBirthPicker
              headerProps={headerProps}
              handleNextSection={handleNextModuleContent}
              data={assessmentStep as ContentfulDateOfBirthPicker}
              section={data?.name!}
            />
          )
        }
        case "ContentfulLanguagePicker": {
          return (
            <LanguagePicker
              headerProps={headerProps}
              handleNextSection={handleNextModuleContent}
              data={assessmentStep as ContentfulLanguagePicker}
              section={data?.name!}
            />
          )
        }
        case "ContentfulCountryPicker": {
          return (
            <CountryPicker
              headerProps={headerProps}
              handleNextSection={handleNextModuleContent}
              data={assessmentStep as ContentfulCountryPicker}
              section={data?.name!}
            />
          )
        }
        case "ContentfulLikertAssessment": {
          return (
            <Likert
              headerProps={headerProps}
              section={data?.name!}
              handleNextSection={handleNextModuleContent}
              data={assessmentStep as ContentfulLikertAssessment}
            />
          )
        }
        case "ContentfulMultipleChoiceQuestion": {
          return (
            <MultipleChoice
              headerProps={headerProps}
              section={data?.name!}
              handleNextSection={handleNextModuleContent}
              data={assessmentStep as ContentfulMultipleChoiceQuestion}
            />
          )
        }
        case "ContentfulImplicitAssociationAssessment": {
          return (
            <ImplicitBiasSurvey
              handleNextSection={handleNextStep}
              data={assessmentStep as ContentfulImplicitAssociationAssessment}
              handleImplicitBiasInput={handleImplicitBiasInput}
              handleSkipPracticeSection={handleSkipPracticeSection}
            />
          )
        }
      }
    }
  }

  if (showOptionalSection) {
    return (
      <ImplicitBiasOptional
        headerProps={headerProps}
        handleConfirmStep={() => setShowOptionalSection(false)}
        handleRejectStep={handleReject}
        overview={
          data?.optionalOverview as ContentfulSurveySectionOptionalOverview
        }
      />
    )
  }

  return <>{renderSurveyContent()}</>
}

export default SurveyModule

import React, { Fragment, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ISection } from 'src/type/globals';
import { handleEndTimer, handleStartTimer } from 'src/utilities/implicit-bias-utils';
import { PaginationHeader } from 'src/components/pagination';
import ImplicitBiasAnswerHeader from './components/implicit-bias-answer-header';
import { ImplicitBiasTutorial } from '.';
import ImplicitBiasAnswer from './implicit-bias-answer';
import { StyledButton } from 'src/components/buttons';
import { ContentfulImplicitAssociationAssessment } from 'types/graphql-types';
import { ImplicitBiasInput } from '../../../../external-api/service-api';
import WeightBiasPagination from 'src/components/pagination/weight-bias-pagination';
import { CustomLayout } from 'src/components/layout';
import { headerTypes, IFooterProps, IHeaderProps } from 'src/components/layout/custom-layout';
import { ButtonType } from 'src/components/buttons/styled-button';

/**
 * Weight bias Section container 
 * In the weight bias section of the survey, there are many different "sections" in it.  
 * 
 */

interface IProps {
    data: ContentfulImplicitAssociationAssessment
    sectionConfig: ISection
    sectionStep: number
    onSectionFinish: () => void
    setAnswer: (answer: ImplicitBiasInput) => void
    totalSections: number
}

const useStyles = makeStyles(theme => ({

    content: {
        '@media only screen and (orientation : landscape)': {
            position: "absolute",
            top: "2.5rem",
            left: "24px",
            right: "24px"
        }
    },

    textContent: {
        '@media only screen and (orientation : landscape)': {
            paddingLeft: "7rem",
            paddingRight: "7rem",
            marginBottom: "22em"
        }
    },
    container: {
        width: "100%",
        overflow: "hidden",
        height: "100%"
    },
    button: {
        marginTop: "auto"
    },

    gridContainer: {
        overflow: "hidden",
        '@media only screen and (orientation : landscape)': {
            position: "relative",
            height: "34.5rem"
        }
    }
}))

const TUTORIALSTEP = 0;
const ImplicitBiasSection: React.FC<IProps> = ({ data, onSectionFinish, sectionConfig, sectionStep, totalSections, setAnswer }) => {
    const [tutorialStep, setTutorialStep] = useState(0);
    const [cardStep, setCardStep] = useState(0);
    const classes = useStyles();
    /**
     * Whenever it goes to next question or section, start the timer. 
     */
    React.useEffect(() => {
        handleStartTimer(sectionStep, cardStep);
    }, [cardStep, sectionStep])

    function handleStartSection() {
        setTutorialStep(prevTutorialStep => prevTutorialStep + 1);
    };

    function handleNextQuestion() {
        setCardStep(prevCardStep => prevCardStep + 1);
    }

    function handleResetCurrentQuestion() {
        handleStartTimer(sectionStep, cardStep);
    }

    function handleFinishSection() {
        setTutorialStep(0);
        setCardStep(0);
        onSectionFinish();
    }

    function handleOnAnswer(correctAnswer: boolean) {
        const duration = handleEndTimer(sectionStep, cardStep);
        const answer: ImplicitBiasInput = {
            responseTimeMilliseconds: Math.round(duration),
            correct: correctAnswer,
            blockID: sectionStep,
            trialID: cardStep + 1
        };

        setAnswer(answer);
        // -- If answer is correct
        if (correctAnswer) {
            const questions = sectionConfig.data;
            if (questions.length - 1 === cardStep) {
                handleFinishSection();
            }
            else {
                handleNextQuestion();
            }
        } else {
            handleResetCurrentQuestion();
        }
    }
    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleStartSection,
            type: ButtonType.CONTINUE,
            isDisabled: false,
            onKeyPressed: () => {
                handleStartSection()
            }
        },
        allowSkip: false
    }

    const headerProps: IHeaderProps = {
        type: headerTypes.questionPagination,
        step: sectionStep,
        allSteps: totalSections
    }

    if (tutorialStep === TUTORIALSTEP) return (
        <CustomLayout
            footerProps={footerProps}
            headerProps={headerProps}
        >
            <Grid container alignItems="center" className={classes.gridContainer}>
                <Grid item xs={12}>
                    <div className={classes.content}>
                        {sectionConfig.leftAnswer && sectionConfig.rightAnswer &&
                            <ImplicitBiasAnswerHeader
                                isMultipleAnswers={sectionConfig.leftAnswer.length > 1 && sectionConfig.rightAnswer.length > 1}
                                leftAnswers={sectionConfig.leftAnswer!}
                                rightAnswers={sectionConfig.rightAnswer!}
                            />
                        }
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.textContent}>
                        <ImplicitBiasTutorial />
                    </div>
                </Grid>
            </Grid>
        </CustomLayout>
    );

    return (
        <>
            {sectionConfig.data &&
                <ImplicitBiasAnswer
                    labelForTouch={data.labelForTouch || ''}
                    question={sectionConfig.data[cardStep]}
                    leftAnswers={sectionConfig.leftAnswer}
                    rightAnswers={sectionConfig.rightAnswer}
                    onAnswer={handleOnAnswer}
                />
            }
        </>
    )
}

export default ImplicitBiasSection;
import React, {  useMemo } from 'react';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    primaryAnswer: {
        display: "block",
    }
}))

interface IProps {
    answer: string[]
}

const ImplicitBiasSingleAnswer: React.FC<IProps> = ({ answer }) => {
    const classes = useStyles();
    return useMemo(() => (
        <span className={classes.primaryAnswer}>{answer[0]}</span>
    ), [answer, classes])
}

export default ImplicitBiasSingleAnswer;
import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { ContentfulDateOfBirthPicker } from "types/graphql-types"
import { ButtonType } from "src/components/buttons/styled-button";
import { graphql } from "gatsby";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MenuItem } from "@material-ui/core";
import { useSurveyDataGetter } from "src/hooks/useDataGetter";
import { setSurveyDataAction, SurveySectionData } from "src/state/features/survey/surveySlice";
import { useDispatch } from "react-redux";
import { CustomLayout } from "../layout";
import { IFooterProps, IHeaderProps } from "../layout/custom-layout";

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "block",
        borderColor: theme.palette.primary.main,
        borderRadius: "4px",
        marginBottom: "2.5rem"
    },
    select: {
        height: "auto",
        fontSize: "1rem",
        lineHeight: "1em",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: "1.5rem",
    },
}));

export type DateOfBirthPickerData = {
    year: number | "",
    month?: number | "",
    day?: number | ""
}

interface IProps {
    data: ContentfulDateOfBirthPicker
    section: string
    handleNextSection: () => void
    headerProps: IHeaderProps
}

export type ContentfulDateOfBirthPickerType =
    ({ __typename: 'ContentfulDateOfBirthPicker' } & ContentfulDateOfBirthPicker);


const DateOfBirthPicker = ({ data, handleNextSection, section, headerProps }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { id, __typename, name, optional, text, minimumAge, maximumAge } = data as ContentfulDateOfBirthPickerType;

    const surveySectionData = useSurveyDataGetter(`${section}.${id}`)
    const componentData: DateOfBirthPickerData = surveySectionData ? surveySectionData.data as DateOfBirthPickerData : { year: "" }


    const [year, setYear] = useState<number | "">(componentData.year);

    const today = new Date()
    const maxYear = today.getFullYear() - (minimumAge ? minimumAge : 0)
    const minYear = today.getFullYear() - (maximumAge ? maximumAge : 0) - 1


    function returnAllYears() {
        let yearMenuItems = [<MenuItem key="dob-year-null" value=""></MenuItem>]
        for (let yearMenuValue = maxYear; yearMenuValue >= minYear; yearMenuValue -= 1) {
            yearMenuItems.push(<MenuItem key={"dob-year-" + yearMenuValue} value={yearMenuValue}>{yearMenuValue}</MenuItem>)
        }
        return yearMenuItems;
    }

    function handleSubmit() {
        const value = {
            name: name,
            type: __typename,
            data: {
                year: typeof year === 'number' ? year : 0
            }
        } as SurveySectionData
        dispatch(setSurveyDataAction({ name: `${section}.${id}`, value: value }))
        handleNextSection();
    }

    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleSubmit,
            type: ButtonType.CONTINUE,
            isDisabled: year === ""
        },
        allowSkip: !!optional,
        skipButton: {
            onClickHandler: handleNextSection
        }
    }

    return (
        <CustomLayout
            headerProps={headerProps}
            footerProps={footerProps}
        >
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3">{text}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="div" className={classes.formControl}>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={year}
                            onChange={(event) => setYear(event.target.value === "" ? "" : parseInt(event.target.value as string))}
                            classes={{
                                select: classes.select,
                                icon: classes.icon,
                            }}
                            inputProps={{
                                name: 'Year',
                                id: 'dob-year',
                            }}
                        >
                            {returnAllYears()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </CustomLayout>
    )
}

export const dateOfBirthPickerFragment = graphql`
fragment dateOfBirthPickerFragment on ContentfulDateOfBirthPicker {
    id
    __typename
    name
    title
    optional
    text
    minimumAge
    maximumAge
    displayDay
    displayMonth
  }
  `

export default DateOfBirthPicker;
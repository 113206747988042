import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { IQuestionType } from 'src/type/globals';

const useStyles = makeStyles(theme => ({
    card: {
        position: "relative",
        flex: "1 1 auto",
        display: "flex",
        maxWidth: "80vh",
        margin: "auto -2.75rem",
        textAlign: "center",
        background: "#fff no-repeat center center",
        backgroundSize: "contain",
        border: "0.125rem solid " + theme.palette.primary.main,
        borderRadius: "100%",
        boxShadow: "0.25rem 0.25rem 0.375rem rgba(0,0,0,0.16)",
        boxSizing: "border-box",
        overflow: "hidden",
        zIndex: 100,

        '&:before': {
            content: "''",
            display: "block",
            width: "100%",
            paddingTop: "100%",
        }
    },
    image: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "auto",
        height: "100%",
        transform: "translate(-50%, -50%)",
    },
    question: {
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "auto",
        fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        fontSize: "1.9375rem",
        fontWeight: 500,
        transform: "translate(-50%, -50%)",
    }
}))

interface IProps {
    question: IQuestionType
}

const ImplicitBiasCard: React.FC<IProps> = ({ question }) => {
    const classes = useStyles();
    return (
        <Fragment>
            {question.type === 0 ?
                <div className={classes.card}>
                    <img className={classes.image} src={question.image.file?.url!} alt={question.expectedAnswer} />
                </div>
                :
                <div className={classes.card}>
                    <strong className={classes.question}>{question.text}</strong>
                </div>
            }
        </Fragment>
    )
}

export default ImplicitBiasCard;
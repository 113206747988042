import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    resultsContainer: {
        padding: theme.spacing(3),
        fontFamily: "Roboto",
        fontSize: "1.1875rem",
        lineHeight: 1.2,
        backgroundColor: "#F5F5F5",
    },
    resultsHeading: {
        margin: "0 0 0.625rem 0",
        fontFamily: "'Open Sans'",
        fontSize: "1.625rem",
        fontWeight: 300,
        lineHeight: 1.2,
    }
}))

interface IProps {
    result: string
    direction: string
}

const ImplicitBiasResultBox: React.FC<IProps> = ({ result, direction }) => {
    const classes = useStyles();

    return (
        <div className={classes.resultsContainer}>
            <strong>Your responses suggested a {result} automatic preference for {direction}</strong>
        </div>
    )
}

export default ImplicitBiasResultBox;
import React, { Fragment } from 'react';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { IQuestionType } from 'src/type/globals';
import { ImplicitBiasCard, ImplicitBiasMultipleAnswers, ImplicitBiasSingleAnswer } from './components';
import { CustomLayout } from 'src/components/layout';
import { IHeaderProps, headerTypes } from 'src/components/layout/custom-layout';
import KeybindButton from 'src/components/buttons/keybind-button';
import { useMediaQuery } from 'react-responsive';
import { useSharedContent } from 'src/hooks/customGraphqlHooks';

/**
 * Weight Bias Answer Container
 * This is the container for the weight bias section where the user selects the answer
 * 
 */

const useStyles = (props: any) => makeStyles(() => ({
    cardRoot: {
        flex: "1 1 auto",
        alignContent: "stretch",
        alignItems: "stretch",
        height: "100vh",
        width: "100%",
        overflow: "hidden"
    },
    answerFeedback: {
        height: "100%"
    },
    answerGroup: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    answeringButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "6.75rem",
        height: "calc(100% - 124px)",
        minHeight: "300px",
        margin: "0 0px",
        padding: "1.5625rem 0.625rem",
        fontSize: "1.0625rem",
        fontWeight: 700,
        lineHeight: "1.2",
        textTransform: "none",
        borderRadius: "0.4375rem",
        boxShadow: "0.25rem 0.25rem 0.375rem rgba(0,0,0,0.16)",

        '&:after': props.isDesktop ? {
            content: "''",
            position: "absolute",
            display: "block",
            top: "-13rem",
            width: "5rem",
            height: "50rem",
            background: "no-repeat center center",
            backgroundSize: "contain",
        } : {},

        '&:first-child:after': props.isDesktop ? {
            right: "1rem",
            backgroundImage: `url(${props.leftImage})`,
        } : {},

        '&:last-child:after': props.isDesktop ? {
            left: "1rem",
            backgroundImage: `url(${props.rightImage})`,
        } : {},
    },
    answeringButtonLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "90%",
    },
    wrongAnswer: {
        color: "#EE0303",
        fontWeight: 700,
    },
    wrongAnswerIcon: {
        display: "block",
        paddingBottom: "0.625rem",
        fontSize: "3.125rem",
        fontWeight: 700,
        lineHeight: 1,
        WebkitTextStroke: "0.25rem #EE0303",
        textStroke: "0.1875rem #EE0303",
    }
}));



interface IProps {
    onAnswer: (isCorrect: boolean) => void
    question: IQuestionType
    leftAnswers: string[]
    rightAnswers: string[]
    labelForTouch: string;
}

const ImplicitBiasAnswer: React.FC<IProps> = ({ question, labelForTouch, onAnswer, leftAnswers, rightAnswers }) => {
    const sharedContent = useSharedContent();
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1280px)'
    });
    const leftImage = sharedContent?.implicitAssociationTutorialDKey?.file?.url
    const rightImage = sharedContent?.implicitAssociationTutorialKKey?.file?.url
    const classes = useStyles({ 
        leftImage,
        rightImage,
        isDesktop,
    })();
    const [answerCorrectness, setCorrectness] = React.useState({
        left: true,
        right: true
    });

    function validateLeftAnswer() {
        const answer = question.expectedAnswer;
        const isCorrect = leftAnswers.some(a => a === answer);

        setCorrectness({
            left: isCorrect,
            right: true
        });
        onAnswer(isCorrect);
    }

    function validateRightAnswer() {
        const answer = question.expectedAnswer;
        const isCorrect = rightAnswers.some(a => a === answer);

        setCorrectness({
            left: true,
            right: isCorrect
        });
        onAnswer(isCorrect);
    }

    function getAnswerLabels(answer: string[]) {
        const multiple = answer.length > 1;
        return (
            <Fragment>
                {multiple ?
                    <ImplicitBiasMultipleAnswers
                        answer={answer}
                    />
                    :
                    <ImplicitBiasSingleAnswer
                        answer={answer}
                    />
                }
            </Fragment>
        )
    }

    function showWrongAnswerFeedback(validateAnswer: () => void) {
        return (
            <Button
                variant="contained"
                color="secondary"
                classes={{
                    root: classes.answeringButton,
                    label: classes.answeringButtonLabel,
                }}
                onClick={validateAnswer}
                disabled
            >
                <div className={classes.wrongAnswer}>
                    <span className={classes.wrongAnswerIcon}>X</span>
                    {labelForTouch}
                </div>
            </Button>
        )
    }

    const headerProps:IHeaderProps ={
        type: headerTypes.none
    }
    return (
        <CustomLayout
            headerProps={headerProps}    
        >
            <Grid container className={classes.cardRoot}>
                <div className={classes.answerGroup}>
                    {!!answerCorrectness.left ?
                        <KeybindButton
                            variant="contained"
                            color="primary"
                            classes={{
                                root: classes.answeringButton,
                                label: classes.answeringButtonLabel,
                            }}
                            onClick={validateLeftAnswer}
                            onKeyPressed={(key) => {
                                if (key !== 'd' && key !== 'D') {
                                    return;
                                }

                                validateLeftAnswer();
                            }}
                        >
                            {getAnswerLabels(leftAnswers)}
                        </KeybindButton>
                        :
                        showWrongAnswerFeedback(validateLeftAnswer)
                    }
                    <ImplicitBiasCard
                        question={question}
                    />
                    {!!answerCorrectness.right ?
                        <KeybindButton
                            variant="contained"
                            color="primary"
                            classes={{
                                root: classes.answeringButton,
                                label: classes.answeringButtonLabel,
                            }}
                            onClick={validateRightAnswer}
                            onKeyPressed={(key) => {
                                if (key !== 'k' && key !== 'K') {
                                    return;
                                }

                                validateRightAnswer();
                            }}
                        >
                            {getAnswerLabels(rightAnswers)}
                        </KeybindButton>
                        :
                        showWrongAnswerFeedback(validateRightAnswer)
                    }

                </div>
            </Grid>
        </CustomLayout>
    )
}


export default ImplicitBiasAnswer;
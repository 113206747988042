import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import { AllContentfulCourseModulesQuery, ContentfulAssessments, ContentfulLearningMaterial } from "types/graphql-types";
import CourseAssessment from "./course-assessment";
import CourseLearningMaterial from "./course-learning-material";


interface IProps {
    courseModuleId: string
    handleNextModule: () => void
}

export type CourseModuleType =
    ({ __typename: 'ContentfulAssessments' } & ContentfulAssessments)
    | ({ __typename: 'ContentfulLearningMaterial' } & ContentfulLearningMaterial)


const CourseModule = ({ courseModuleId, handleNextModule }: IProps) => {
    const data = useStaticQuery<AllContentfulCourseModulesQuery>(graphql`
    query AllContentfulCourseModules {
        allContentfulCourseModule {
            edges {
                node {
                    id
                    slug
                    title
                    __typename
                    moduleContent {                
                        ... on ContentfulAssessments {
                            id
                            slug
                            title
                            __typename
                        }
                        ... on ContentfulLearningMaterial {
                            id
                            slug
                            title
                            __typename
                        }
                    }
                }
            }
        }
    }
`).allContentfulCourseModule.edges.find(x => x?.node?.id === courseModuleId)?.node;
    const modulesContent = data?.moduleContent;
    const [courseStep, setCourseStep] = useState(0);
    
    function nextCourseStep() {
        if (modulesContent && courseStep < modulesContent.length - 1) {
            setCourseStep(courseStep + 1);
        } else {
            setCourseStep(0);
            handleNextModule();
        }
    }

    function renderCourseContent() {
        if (modulesContent && modulesContent.length > 0) {
            const currentModule = modulesContent[courseStep] as CourseModuleType;
            if (currentModule) {
                switch (currentModule.__typename) {
                    case "ContentfulAssessments": return (
                        <CourseAssessment id={currentModule?.id} currentModuleIndex={courseStep + 1} totalModules={modulesContent.length} nextStep={nextCourseStep} />
                    )
                    case "ContentfulLearningMaterial": return (
                        <CourseLearningMaterial id={currentModule?.id} currentModuleIndex={courseStep + 1} totalModules={modulesContent.length} nextStep={nextCourseStep} />
                    )
                }
            }
        }
    }

    return (
        <>
            {renderCourseContent()}
        </>
    );
}

export default CourseModule;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ButtonType } from "src/components/buttons/styled-button";
import { graphql, useStaticQuery } from "gatsby";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { useDispatch, useSelector } from "react-redux";
import { resetAnswers, setCourse } from "src/state/features/lms/lmsSlice";
import { ButtonState } from "src/state/features/ui/uiSlice";
import { getButtonState } from "src/state/features/ui/uiSelector";
import { AllContentfulCoursesQuery } from "types/graphql-types";
import { navigate, RouteComponentProps, useLocation } from '@reach/router';

import CustomLayout, { headerTypes, IHeaderProps } from "src/components/layout/custom-layout";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    container: {
        height: "80vh"
    },
    button: {
        marginTop: "auto"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

interface IProps extends RouteComponentProps {
    courseSlug?: string;
}

const Course = ({ courseSlug }: IProps) => {
    const data = useStaticQuery<AllContentfulCoursesQuery>(graphql`
    query AllContentfulCourses {
        allContentfulCourse {
            edges {
                node {
                    id
                    title
                    slug
                    contentful_id
                    overview {
                        raw
                        references {
                            __typename
                            ... on ContentfulVideo {
                                ...videoFragment
                            }
                            ... on ContentfulAsset {
                                ...assetFragment
                            }
                        }
                    }
                    coursePassThreshold
                }
            }
        }
    }
`).allContentfulCourse.edges.find(x => x?.node?.slug === courseSlug)?.node;

    const navigation = useLocation();
    const dispatch = useDispatch();
    const buttonState = useSelector(getButtonState);

    function handleStartCourse() {
        dispatch(setCourse({
            courseId: data?.id,
            contentfulId: data?.contentful_id,
            slug: data?.slug,
        }));
        const passThreshold = data?.coursePassThreshold ?? 75;
        dispatch(resetAnswers({ passThreshold: (passThreshold / 100) || -1 }));

        navigate(`${navigation.pathname}/${data?.id}`)
    }

    const footerProps = {
        submitButton: {
            onClickHandler: handleStartCourse,
            type: ButtonType.CONTINUE,
            isDisabled: buttonState === ButtonState.Disabled
        },
        allowSkip: false
    }

    const headerProps: IHeaderProps = {
        type: headerTypes.contentPagination,
        title: data?.title!,
        step: 0,
        allSteps: 0
    }

    return (
        <CustomLayout
            footerProps={footerProps}
            headerProps={headerProps}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ContentfulRichText {...data?.overview as ContentfulRichTextType} />
                </Grid>
            </Grid>
        </CustomLayout>
    );
}

export default Course;
import React, { useLayoutEffect } from "react"
import { Router } from "@reach/router"
import Survey from "src/client-pages/survey/survey"
import Course from "src/client-pages/course/course"
import { Paths } from "src/utilities/constants"
import CourseResults from "src/client-pages/course/course-results"
import SurveyResults from "src/client-pages/survey/survey-results"
import SurveyModuleStepper from "src/client-pages/survey/survey-module-stepper"
import CourseModuleStepper from "src/client-pages/course/course-module-stepper"
import SelectionPage from "src/client-pages/selection"
import { getLanguageFromNavigator } from "src/utilities/implicit-bias-utils"
import { updateLanguage } from "src/state/features/ui/uiSlice"
import { useDispatch } from "react-redux"

const App = () => {
  const dispatch = useDispatch();
    
  useLayoutEffect(() => {
    const lang = getLanguageFromNavigator();
    dispatch(updateLanguage(lang));
  }, [])

    return (
        <Router>
            <Survey path={`${Paths.SURVEY}/:surveySlug`} />
            <SurveyModuleStepper path={`${Paths.SURVEY}/:surveySlug/:surveyId`} />
            <SelectionPage path={Paths.SELECTION} />
            <SurveyResults path={Paths.SURVEY_RESULTS}  />
            <Course path={`${Paths.COURSE}/:courseSlug`}  />
            <CourseModuleStepper path={`${Paths.COURSE}/:courseSlug/:courseId`} />
            <CourseResults path={`${Paths.COURSE_RESULTS}`} />
        </Router>
    )
}

export default App;

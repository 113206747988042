// Template component used by gatsby-node.js for programmatically creating course pages
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { graphql, useStaticQuery } from "gatsby";
import { ContentfulMultipleChoiceQuestion, ContentfulDragAndDrop, ContentfulMultiSelectAssessment, ContentfulMultiRadioSelectAssessment, AllContentfulAssessmentsQuery, ContentfulAssessments } from "types/graphql-types";
import PaginationHeader from "src/components/pagination/pagination-header";
import { DragAndDrop, MultipleChoice, MultipleChoiceWithFeedback, MultiRadioSelect, MultiSelect } from "src/components/assessments";
import { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";

interface IProps {
    id: string
    currentModuleIndex: number
    totalModules: number
    nextStep: () => void;
}

export type AssessmentType =
    ({ __typename: 'ContentfulDragAndDrop' } & ContentfulDragAndDrop)
    | ({ __typename: 'ContentfulMultipleChoiceQuestion' } & ContentfulMultipleChoiceQuestion)
    | ({ __typename: 'ContentfulMultiSelectAssessment' } & ContentfulMultiSelectAssessment)
    | ({ __typename: 'ContentfulMultiRadioSelectAssessment' } & ContentfulMultiRadioSelectAssessment)


const CourseAssessment = ({ id, currentModuleIndex, totalModules, nextStep }: IProps) => {
    const data = useStaticQuery<AllContentfulAssessmentsQuery>(graphql`
    query AllContentfulAssessments {
        allContentfulAssessments {
          edges {
            node {
              id
              slug
              title
              name
              debriefTitle
              __typename
              assessment {
                __typename
                ... on ContentfulMultiRadioSelectAssessment {
                  ...multiRadioSelectFragment
                }
                ... on ContentfulMultiSelectAssessment {
                  ...multiSelectFragment
                }
                ... on ContentfulDragAndDrop {
                  ...dragAndDropFragment
                }
                ... on ContentfulMultipleChoiceQuestion {
                  ...multipleChoiceFragment
                }
              }
            }
          }
        }
      }
`).allContentfulAssessments.edges.find(x => x?.node.id === id)?.node;

    const headerProps: IHeaderProps = {
        type: headerTypes.contentPagination,
        title: data?.title || "",
        step: currentModuleIndex,
        allSteps: totalModules
    }

    function renderAssessment() {
        const assessment = data?.assessment as AssessmentType
        switch (assessment.__typename) {
            case "ContentfulDragAndDrop": {
                return (
                    <DragAndDrop
                        headerProps={headerProps}
                        handleNextSection={nextStep}
                        data={assessment as ContentfulDragAndDrop}
                        debriefTitle={data?.debriefTitle || ""}
                    />
                )
            }
            case "ContentfulMultipleChoiceQuestion": {
                return (
                    <MultipleChoiceWithFeedback
                        headerProps={headerProps}
                        section={data?.name!}
                        handleNextSection={nextStep}
                        data={assessment as ContentfulMultipleChoiceQuestion}
                        debriefTitle={data?.debriefTitle || ""}
                    />
                )
            }
            case "ContentfulMultiSelectAssessment": {
                return (
                    <MultiSelect
                        headerProps={headerProps}
                        handleNextSection={nextStep}
                        data={assessment as ContentfulMultiSelectAssessment}
                        debriefTitle={data?.debriefTitle || ""}
                    />
                )
            }
            case "ContentfulMultiRadioSelectAssessment": {
                
                return (
                    <MultiRadioSelect
                        headerProps={headerProps}
                        handleNextSection={nextStep}
                        data={assessment as ContentfulMultiRadioSelectAssessment}
                        debriefTitle={data?.debriefTitle || ""}
                    />
                )
            }
        }
    }

    return (
        <>
            {renderAssessment()}
        </>
    );
}

export default CourseAssessment;
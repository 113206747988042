import React, { Fragment } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { ContentfulSurveySectionOptionalOverview } from 'types/graphql-types';
import ContentfulRichText, { ContentfulRichTextType } from 'src/contentful/contentful-rich-text';
import { StyledButton } from 'src/components/buttons';
import { PaginationHeader } from 'src/components/pagination';
import { CustomLayout } from 'src/components/layout';
import { IHeaderProps } from 'src/components/layout/custom-layout';
import { ButtonType } from 'src/components/buttons/styled-button';


/**
 * Optional questions page
 * This step is optional for the user to do 
 * 
 */
const useStyles = makeStyles(theme => ({
    container: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
    },
    button: {},
    IAToverview: {
        wordBreak: "break-word",
        padding: "3rem 0px"
    }
}))
interface IProps {
    handleConfirmStep: () => void
    handleRejectStep: () => void
    overview: ContentfulSurveySectionOptionalOverview
    headerProps: IHeaderProps
}

const ImplicitBiasOptional: React.FC<IProps> = ({ handleConfirmStep, handleRejectStep, overview, headerProps }) => {
    const classes = useStyles();

    const footerProps = {
        submitButton: {
            onClickHandler: handleConfirmStep,
            type: ButtonType.CONTINUE,
            isDisabled: false
        },
        allowSkip: true,
        skipButton: {
            onClickHandler: handleRejectStep
        }
    }

    return (
        <CustomLayout
            headerProps={headerProps}
            footerProps={footerProps}
        >
            <Grid container className={classes.container}>
                {overview &&
                    <Grid item xs={12} className={classes.IAToverview}>
                        <ContentfulRichText {...overview as unknown as ContentfulRichTextType} />
                    </Grid>
                }
            </Grid>
        </CustomLayout>
    )
}

export default ImplicitBiasOptional;
import React, { useCallback, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { ContentfulCountryPicker } from "types/graphql-types"
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import { graphql } from "gatsby";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MenuItem } from "@material-ui/core";
import { useSurveyDataGetter } from "src/hooks/useDataGetter";
import { setSurveyDataAction, SurveySectionData } from "src/state/features/survey/surveySlice";
import { useDispatch } from "react-redux";
import { Country, countries } from "countries-list"
import { CustomLayout } from "../layout";
import { IFooterProps, IHeaderProps } from "../layout/custom-layout";

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "block",
        borderColor: theme.palette.primary.main,
        borderRadius: "4px",
        marginBottom: "2.5rem"
    },
    select: {
        height: "auto",
        fontSize: "1rem",
        lineHeight: "1em",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: "1.5rem",
    },
}));

export type CountryPickerData = {
    country: Country | ""
}

interface IProps {
    data: ContentfulCountryPicker
    section: string
    handleNextSection: () => void
    headerProps: IHeaderProps
}
export type ContentfulCountryPickerType =
    ({ __typename: 'ContentfulCountryPicker' } & ContentfulCountryPicker);


const CountryPicker = ({ data, handleNextSection, section, headerProps }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { id, __typename, name, optional, text } = data as ContentfulCountryPickerType;

    const surveySectionData = useSurveyDataGetter(`${section}.${id}`)
    const componentData: CountryPickerData = surveySectionData ? surveySectionData.data as CountryPickerData : { country: "" }

    const [country, setCountry] = useState<Country | "">(componentData.country);

    function returnAllCountries() {
        let countryMenuItems = [<MenuItem key="country-null" value=""></MenuItem>]
        let countryNameCode = [] as string[][]
        Object.entries(countries).map(([countryCode, countryData]) => countryNameCode.push([countryData.name, countryCode]))
        countryNameCode.sort((a, b) => a > b ? 1 : -1).map(
            ([countryName, countryCode]) => countryMenuItems.push(
                <MenuItem key={"country-" + countryCode} value={countryCode}>{countryName}</MenuItem>
            ))
        return countryMenuItems;
    }

    function handleSubmit() {
        const value = {
            name: name,
            type: __typename,
            data: {
                country: country
            }
        } as SurveySectionData
        dispatch(setSurveyDataAction({ name: `${section}.${id}`, value: value }))
        handleNextSection();
    }

    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleSubmit,
            type: ButtonType.CONTINUE,
            isDisabled: country === ""
        },
        allowSkip: !!optional,
        skipButton: {
            onClickHandler: handleNextSection
        }
    }

    return (
        <CustomLayout
            headerProps={headerProps}
            footerProps={footerProps}
        >
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3">{text}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="div" className={classes.formControl}>
                        <Select
                            fullWidth
                            variant="outlined"
                            value={country}
                            onChange={(event) => setCountry(event.target.value as Country | "")}
                            classes={{
                                select: classes.select,
                                icon: classes.icon,
                            }}
                            inputProps={{
                                name: 'country',
                                id: 'country',
                            }}
                        >
                            {returnAllCountries()}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </CustomLayout>
    )
}

export const countryPickerFragment = graphql`
fragment countryPickerFragment on ContentfulCountryPicker {
    id
    __typename
    name
    title
    optional
    text
  }
  `

export default CountryPicker;
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ContentfulRichText, { ContentfulRichTextType } from 'src/contentful/contentful-rich-text';
import { graphql, useStaticQuery } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { getLanguage } from 'src/state/features/ui/uiSelector';

/**
 * Tutorial Container
 * Container for the tutorial section of the weight bias survey.
 * There is a tutorial for each "Section" of the weight bias survey
 * 
 */

const useStyles = makeStyles(theme => ({
    content: {
        padding: "2.5rem 3.125rem 0 3.125rem",
        textAlign: "center",
        marginTop: "40px"
    },

    primaryAnswer: {
        color: "#03A208"
    },
    secondaryAnswer: {
        color: "#042AC1"
    },
    errorMarker: {
        color: "red"
    }
}))

interface IProps {
    overview?: any 
}

const ImplicitBiasTutorial: React.FC<IProps> = ({ overview }) => {
    const lang = useSelector(getLanguage);
    const classes = useStyles();
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1280px)'
      });
    const fullTutorialContent = useStaticQuery<any>(graphql`
        query ContentfulTutorial {
            allContentfulTutorial {
                edges {
                node {
                    description {
                    raw
                    }
                    node_locale
                    isMobile
                }
                }
            }
        }  
    `).allContentfulTutorial.edges;
    const tutorialContent = fullTutorialContent.find((x: any) => x?.node?.node_locale === lang && ((isDesktop && !x?.node?.isMobile) || (!isDesktop && x?.node?.isMobile)))?.node
        || fullTutorialContent.find((x: any) => ((isDesktop && !x?.node?.isMobile) || (!isDesktop && x?.node?.isMobile)))?.node;
    return (
        <div className={classes.content}>
           <ContentfulRichText {...tutorialContent.description as unknown as ContentfulRichTextType} />
        </div>
    )
}

export default ImplicitBiasTutorial;
import React from "react"
import { makeStyles, Button } from "@material-ui/core"
import { ContentfulImplicitAssociationAssessment } from "types/graphql-types"
import ContentfulRichText, {
  ContentfulRichTextType,
} from "src/contentful/contentful-rich-text"
import { CustomLayout } from "src/components/layout"
import {
  IFooterProps,
  IHeaderProps,
  headerTypes,
} from "src/components/layout/custom-layout"
import { ButtonType } from "src/components/buttons/styled-button"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingBottom: "1rem",
  },
  rta: {
    marginBottom: "auto",
  },
}))

interface IProps {
  data: ContentfulImplicitAssociationAssessment
  handleSkip: () => void
  handleContinue: () => void
}

const ImplicitBiasPractice: React.FC<IProps> = ({
  handleSkip,
  handleContinue,
  data,
}) => {
  const classes = useStyles()

  const footerProps: IFooterProps = {
    submitButton: {
      onClickHandler: handleContinue,
      type: ButtonType.CONTINUE,
      isDisabled: false,
      label: data.continuePracticeLabel ?? "Start Practice Round",
    },
    allowSkip: true,
    skipButton: {
      onClickHandler: handleSkip,
      label: data.skipPracticeLabel ?? "Skip Practice Round",
    },
  }

  const headerProps: IHeaderProps = {
    type: headerTypes.logo,
  }

  return (
    <CustomLayout headerProps={headerProps} footerProps={footerProps}>
      <div className={classes.container}>
        <div className={classes.rta}>
          {data?.practiceRoundRta && (
            <ContentfulRichText
              {...(data?.practiceRoundRta as ContentfulRichTextType)}
            />
          )}
        </div>
      </div>
    </CustomLayout>
  )
}

export default ImplicitBiasPractice

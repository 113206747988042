// Template component used by gatsby-node.js for programmatically creating course pages
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { graphql, useStaticQuery } from "gatsby";
import { AllContentfulLearningMaterialQuery, } from "types/graphql-types";
import PaginationHeader from "src/components/pagination/pagination-header";
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import { useSelector } from "react-redux";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { getButtonState } from "src/state/features/ui/uiSelector";
import { ButtonState } from "src/state/features/ui/uiSlice";
import { CustomLayout } from "src/components/layout";
import { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";


interface IProps {
  id: string
  currentModuleIndex: number
  totalModules: number

  nextStep: () => void;
}

// -- List of sections with feedback.

const CourseLearningMaterial = ({ id, currentModuleIndex, totalModules, nextStep }: IProps) => {
  const data = useStaticQuery<AllContentfulLearningMaterialQuery>(graphql`
    query AllContentfulLearningMaterial {
        allContentfulLearningMaterial {
          edges {
            node {
              slug
              title
              id
              content {
                raw
                references {
                  __typename
                  ... on ContentfulAsset {
                    ...assetFragment
                  }
                  ... on ContentfulVideo {
                    ...videoFragment
                  }
                }
              }
            }
          }
        }
      }
`).allContentfulLearningMaterial.edges.find(x => x.node.id === id)?.node;
  const buttonState = useSelector(getButtonState);

  const footerProps = {
    submitButton: {
      onClickHandler: nextStep,
      type: ButtonType.CONTINUE,
      isDisabled: buttonState === ButtonState.Disabled
    },
    allowSkip: false
  }

  const headerProps: IHeaderProps = {
    type: headerTypes.contentPagination,
    step: currentModuleIndex,
    allSteps: totalModules,
    title: data?.title!
  }

  return (
    <CustomLayout
      footerProps={footerProps}
      headerProps={headerProps}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContentfulRichText {...data?.content as ContentfulRichTextType} />
        </Grid>
      </Grid>
    </CustomLayout>
  );
}

export default CourseLearningMaterial;
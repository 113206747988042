import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Grid } from "@material-ui/core"
import { StyledButton } from "src/components/buttons"
import { RouteComponentProps } from "@reach/router"
import { Paths } from "src/utilities/constants"
import ContentfulRichText, {
  ContentfulRichTextType,
} from "src/contentful/contentful-rich-text"
import { CustomLayout } from "src/components/layout"
import { IHeaderProps, headerTypes } from "src/components/layout/custom-layout"
import { useDispatch, useSelector } from "react-redux"
import {
  getAvailablePackages,
  getHistoricalResults,
  getUser,
  getUserDetails,
  isUserFree,
} from "src/state/features/user/userSelector"
import {
  getUserPackages,
  getUserPackagesBySchedule,
} from "src/state/features/user/userSlice"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "8px",
  },
  button: {
    marginTop: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}))

interface IProps extends RouteComponentProps {}

const SelectionPage = ({}: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userInfo = useSelector(getUser)
  const anonymousUser = useSelector(getUserDetails)
  const [isdefaultSelection, setIsdefaultSelection] = useState(true)
  const availablePackages = useSelector(getAvailablePackages)
  const results = useSelector(getHistoricalResults)
  const isFreeUser = useSelector(isUserFree)
  const data = useStaticQuery<any>(graphql`
    query AllContentfulSurveyAndCourse {
      allContentfulSurvey {
        edges {
          node {
            id
            contentful_id
            title
            slug
            availableForPublic
          }
        }
      }
      allContentfulCourse(
        filter: { node_locale: { eq: "en-US" } }
        sort: { order: DESC, fields: updatedAt }
      ) {
        edges {
          node {
            id
            contentful_id
            title
            slug
            courseTitle
            availableForPublic
          }
        }
      }
      allContentfulSurveySelectionPage {
        edges {
          node {
            id
            content {
              raw
            }
          }
        }
      }
      allContentfulCourseSelectionPage {
        edges {
          node {
            id
            content {
              raw
            }
          }
        }
      }
    }
  `)
  const surveyList = (data.allContentfulSurvey as any).edges
    .map((edge: any) => edge.node)
    .filter(
      (content: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (repeatedContent: any) =>
            repeatedContent.contentful_id === content.contentful_id
        )
    )
  const courseList = (data.allContentfulCourse as any).edges
    .map((edge: any) => edge.node)
    .filter(
      (content: any, index: number, self: any) =>
        index ===
        self.findIndex(
          (repeatedContent: any) =>
            repeatedContent.contentful_id === content.contentful_id
        )
    )

  const availableSurveys = isdefaultSelection
    ? surveyList.filter((node: any) => node.availableForPublic)
    : surveyList.filter(
        (node: any) =>
          availablePackages.testConfiguration &&
          availablePackages?.testConfiguration
            ?.map((test) => test.id)
            .includes(node.contentful_id)
      )

  const availableCourses = isdefaultSelection
    ? courseList.filter((node: any) => node.availableForPublic)
    : courseList.filter(
        (node: any) =>
          availablePackages?.courseConfiguration &&
          availablePackages?.courseConfiguration
            ?.map((course) => course.id)
            .includes(node.contentful_id)
      )

  useEffect(() => {
    async function getPackage() {
      await dispatch(getUserPackages(userInfo.token!))
    }
    async function getPackageByScheduleId() {
      await dispatch(getUserPackagesBySchedule(anonymousUser.scheduleId))
    }
    if (userInfo.token) {
      getPackage()
      setIsdefaultSelection(false)
    } else if (anonymousUser.isAnonymousUser) {
      getPackageByScheduleId()
      setIsdefaultSelection(false)
    } else {
      setIsdefaultSelection(true)
    }
  }, [anonymousUser.isAnonymousUser, isFreeUser])

  function handleSelectSurvey(slug: string) {
    navigate(`${Paths.SURVEY}/${slug}`)
  }

  function handleCourseSurvey(slug: string) {
    navigate(`${Paths.COURSE}/${slug}`)
  }

  function getCourse(id: string) {
    // get label from contentful
    let label = "Locked"
    const courseResults = results.course
    const testResults = results.test
    const availableCourses = availablePackages.courseConfiguration
    const availableTests = availablePackages.testConfiguration

    if (anonymousUser.isAnonymousUser) {
      return {
        isLocked: false,
        label: "Start",
        passed: true,
      }
    }
    if (testResults.length === 0) {
      return {
        isLocked: true,
        label,
        passed: false,
      }
    }

    const prevCourseResults = courseResults.filter((x) => x.courseId === id)

    if (prevCourseResults.length > 0) {
      const passed = courseResults.some((x) => x.passed === true) ?? false

      return {
        isLocked: false,
        label: passed ? "Passed" : "Start",
        passed: passed,
      }
    }

    const categoryId = availableCourses?.find((x) => x.id === id)?.categoryId
    const associatedTest = availableTests?.find(
      (x) => x.categoryId === categoryId
    )?.id
    const isTestDone = testResults.some((x) => x.testId === associatedTest)

    return {
      isLocked: !isTestDone,
      label: isTestDone ? "Start" : "Locked",
      passed: false,
    }
  }

  const headerProps: IHeaderProps = {
    type: headerTypes.logo,
  }

  const renderFreeSelection = () => {
    return (
      <>
        {availableSurveys.length > 0 && (
          <Grid container spacing={0}>
            <ContentfulRichText
              {...(data?.allContentfulSurveySelectionPage.edges[0].node
                .content as ContentfulRichTextType)}
            />
            {availableSurveys.map((survey: any, index: number) => (
              <StyledButton
                key={index}
                fullWidth
                label={survey.title!}
                onClickHandler={() => handleSelectSurvey(survey.slug!)}
              />
            ))}
          </Grid>
        )}
      </>
    )
  }

  const renderParticipantSelection = () => {
    return (
      <>
        {availableSurveys.length > 0 && (
          <Grid container spacing={0}>
            <ContentfulRichText
              {...(data?.allContentfulSurveySelectionPage.edges[0].node
                .content as ContentfulRichTextType)}
            />
            {availableSurveys.map((survey: any, index: number) => (
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <StyledButton
                    key={index}
                    fullWidth
                    label={survey.title!}
                    onClickHandler={() => handleSelectSurvey(survey.slug!)}
                    removePadding
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledButton
                    key={index}
                    fullWidth
                    label={
                      results.test.some(
                        (x) => x.testId === survey.contentful_id
                      )
                        ? "Done"
                        : "Start"
                    }
                    onClickHandler={() => handleSelectSurvey(survey.slug!)}
                    removePadding
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {availableCourses.length > 0 && (
          <Grid container spacing={0}>
            <ContentfulRichText
              {...(data?.allContentfulCourseSelectionPage.edges[0].node
                .content as ContentfulRichTextType)}
            />
            {availableCourses.map((course: any, index: number) => {
              const { isLocked, label } = getCourse(course.contentful_id)
              return (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <StyledButton
                      key={index}
                      fullWidth
                      label={course.courseTitle || course.title!}
                      onClickHandler={() => handleCourseSurvey(course.slug!)}
                      removePadding
                      isDisabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton
                      key={index}
                      fullWidth
                      label={label}
                      onClickHandler={() => handleCourseSurvey(course.slug!)}
                      removePadding
                      isDisabled={isLocked}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        )}
      </>
    )
  }

  return (
    <CustomLayout footerProps={{}} headerProps={headerProps}>
      {isFreeUser ? renderFreeSelection() : renderParticipantSelection()}
    </CustomLayout>
  )
}

export default SelectionPage

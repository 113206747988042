
import { createSelector } from "@reduxjs/toolkit";
import { ICourseScore, ILmsState } from "./lmsSlice";

const _getCourse = (state: { lmsStore: ILmsState }) => state.lmsStore.currentCourse;
const _getScore = (state: { lmsStore: ILmsState }) => state.lmsStore.currentScore;
export const lmsSelector = (state: { lmsStore: ILmsState }) => state.lmsStore
export const getCurrentScore = (state: { lmsStore: ILmsState }) => state.lmsStore.currentScore.totalAnswersCount === 0 ? 0 : (state.lmsStore.currentScore.correctAnswersCount / state.lmsStore.currentScore.totalAnswersCount)
export const getTotalScore = (state: { lmsStore: ILmsState }) => state.lmsStore.currentScore.totalAnswersCount;


export const getCourse = createSelector(
    [_getCourse],
    (course: { courseId: string, slug: string } | null) => {
        if (course) {
            return course;
        }
    }
)

export const getCourseResult = createSelector(
    [_getScore],
    (score: ICourseScore) => {
        const { correctAnswersCount, totalAnswersCount, passThreshold } = score;
        if (correctAnswersCount / totalAnswersCount >= passThreshold) {
            return true;
        }
        return false;
    }
)
import React, { useState } from "react"
import { ISection } from "src/type/globals"
import { ImplicitBiasConverter } from "src/utilities/implicit-bias-utils"
import { ContentfulImplicitAssociationAssessment } from "types/graphql-types"
import { ImplicitBiasIntroductionPage, ImplicitBiasSection } from "."
import { ImplicitBiasInput } from "../../../../external-api/service-api"
import { graphql } from "gatsby"
import ImplicitBiasPracticeIsOver from "./components/implicit-bias-practice-is-over"
import ImplicitBiasPractice from "./components/implicit-bias-practice"

interface IProps {
  data: ContentfulImplicitAssociationAssessment
  handleNextSection: () => void
  handleImplicitBiasInput: (answer: ImplicitBiasInput) => void
  handleSkipPracticeSection: () => void
}

const ImplicitBiasSurvey: React.FC<IProps> = ({
  data,
  handleNextSection,
  handleImplicitBiasInput,
  handleSkipPracticeSection,
}) => {
  const [sections] = useState<ISection[]>(
    ImplicitBiasConverter(data) as ISection[]
  )
  const [sectionStep, setSectionStep] = useState(0)
  const [showIntroduction, setIntroduction] = useState(true)
  const [showSkip, setShowSkip] = useState(true)
  const [showPracticeIsOver, setShowPracticeIsOver] = useState(false)

  const handleNext = () => {
    if (sectionStep === sections.length - 1) {
      handleNextSection()
      return
    }

    const nextSectionStep = sectionStep + 1

    if (nextSectionStep === 1) {
      setShowPracticeIsOver(true)
    }

    setSectionStep(nextSectionStep)
  }

  const handleSkip = () => {
    handleSkipPracticeSection()
    setShowSkip(false)
    handleNext()
  }

  if (showIntroduction) {
    return (
      <ImplicitBiasIntroductionPage
        data={data}
        handleNextSurveyStep={() => setIntroduction(false)}
      />
    )
  }

  if (showSkip) {
    return (
      <ImplicitBiasPractice
        data={data}
        handleSkip={() => handleSkip()}
        handleContinue={() => setShowSkip(false)}
      />
    )
  }

  if (showPracticeIsOver) {
    return (
      <ImplicitBiasPracticeIsOver
        data={data}
        handleContinue={() => setShowPracticeIsOver(false)}
      />
    )
  }

  return (
    <>
      <ImplicitBiasSection
        data={data}
        onSectionFinish={handleNext}
        sectionConfig={sections[sectionStep]}
        sectionStep={sectionStep + 1}
        totalSections={sections.length}
        setAnswer={handleImplicitBiasInput}
      />
    </>
  )
}

export const implicitBiasAssessmentFragment = graphql`
  fragment implicitBiasAssessmentFragment on ContentfulImplicitAssociationAssessment {
    id
    title
    __typename
    secondCategoryTutorialImage {
      id
      contentful_id
      file {
        url
        contentType
      }
      fixed {
        ...GatsbyContentfulFixed
      }
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    firstCategoryTutorialImage {
      id
      contentful_id
      file {
        url
        contentType
      }
      fixed {
        ...GatsbyContentfulFixed
      }
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    firstAssociationPair {
      id
      image {
        id
        file {
          url
          contentType
        }
      }
      list {
        id
        image {
          id
          file {
            url
            contentType
          }
        }
        text
        title
      }
      text
      title
    }
    secondAssociationPair {
      id
      image {
        id
        file {
          url
          contentType
        }
      }
      list {
        id
        image {
          id
          file {
            url
            contentType
          }
        }
        text
        title
      }
      text
      title
    }
    labelForNo
    labelForLow
    labelForMedium
    labelForHigh
    resultTextNoBias
    resultTextBiased
    labelForTouch
    practiceRoundRta {
      raw
    }
    practiceIsOverRta {
      raw
    }
    skipPracticeLabel
    continuePracticeLabel
    practiceIsOverContinueLabel
  }
`

export default ImplicitBiasSurvey

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import StyledButton from "src/components/buttons/styled-button";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { enableButton, resetHeaderVisibility, startCourse } from "src/state/features/ui/uiSlice";
import { Typography } from "@material-ui/core";
import { getCourse, getCourseResult, getCurrentScore } from "src/state/features/lms/lmsSelector";
import { AllContentfulCourseResultsQuery } from "types/graphql-types";
import { RouteComponentProps } from '@reach/router';
import { Paths } from "src/utilities/constants";
import { resetAnswers } from "src/state/features/lms/lmsSlice";
import { CustomLayout } from "src/components/layout";
import { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "75vh"
    },
    button: {
        marginTop: "auto"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    passedHeader: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#03a208",
        textAlign: "center",
        fontFamily: "'Roboto Condensed'",
        marginBottom: "1em !important"
    },
    failedHeader: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#FF0000",
        textAlign: "center",
        fontFamily: "'Roboto Condensed'",
        marginBottom: "1em !important"
    },
    header2: {
        fontSize: "2rem",
        textAlign: "center",
        fontFamily: "'Roboto Condensed'",
    },
    score: {
        textAlign: "center",
        fontSize: "4rem",
        fontWeight: "bold",
        fontFamily: "'Roboto Condensed'",
    }
}));


interface IProps extends RouteComponentProps {

}

const CourseResults = ({ }: IProps) => {
    const course = useSelector(getCourse);
    const currentScore = useSelector(getCurrentScore);
    const isPassed = useSelector(getCourseResult);

    const data = useStaticQuery<AllContentfulCourseResultsQuery>(graphql`
        query AllContentfulCourseResults {
            allContentfulCourse {
            edges {
                node {
                id
                courseResultPageTitle
                coursePassThreshold
                coursePassedText
                courseFailedText
                finalScoreText
                retakeCourseButtonLabel
                continueButtonLabel
                }
            }
            }
        }`
    ).allContentfulCourse.edges.find(x => x?.node?.id === course?.courseId)?.node;

    const dispatch = useDispatch();
    const classes = useStyles();

    if (data === null || undefined) return (
        <>
            No Content
        </>
    );

    function handleRetakeCourse() {
        dispatch(enableButton());
        dispatch(startCourse());
        dispatch(resetAnswers({ passThreshold: (data?.coursePassThreshold ? data?.coursePassThreshold / 100 : -1) ?? -1 }));
        navigate(`${Paths.COURSE}/${course?.slug}`);
    };

    function handleContinue() {
        dispatch(resetHeaderVisibility());
        navigate(`${Paths.SELECTION}`);
    };
    const headerProps: IHeaderProps = {
        type: headerTypes.contentPagination,
        title: data?.courseResultPageTitle!,
        step: 0,
        allSteps: 0
    }

    return (
        <CustomLayout
            headerProps={headerProps}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.container}>
                        {isPassed &&
                            <Grid item xs={12}>
                                <Typography className={classes.passedHeader} variant="body2">{data?.coursePassedText}</Typography>
                            </Grid>
                        }
                        {(!isPassed) &&
                            <Grid item xs={12}>
                                <Typography className={classes.failedHeader} variant="body2">{data?.courseFailedText}</Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography className={classes.header2} variant="h2">{data?.finalScoreText}</Typography>
                            <br />
                            <Typography className={classes.score} variant="h2">{Math.round(currentScore * 100)}%</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.button}>
                            {!isPassed &&
                                <StyledButton
                                    label={data?.retakeCourseButtonLabel ?? ""}
                                    fullWidth
                                    onClickHandler={handleRetakeCourse}
                                />
                            }
                            <StyledButton
                                label={data?.continueButtonLabel ?? ""}
                                fullWidth
                                onClickHandler={handleContinue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomLayout>
    );
}

export default CourseResults;
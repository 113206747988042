import React, { Fragment, useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import { useSharedContent } from "src/hooks/customGraphqlHooks"

const useStyles = makeStyles((theme) => ({
  seperator: {
    display: "block",
    margin: "0.75rem auto",
  },
  primaryAnswer: {
    display: "block",
  },
  secondaryAnswer: {
    display: "inline-block",
  },
}))

interface IProps {
  answer: string[]
}

const ImplicitBiasMultipleAnswers: React.FC<IProps> = ({ answer }) => {
  const sharedContent = useSharedContent()
  const classes = useStyles()
  const separatorTextLabel = sharedContent?.separatorTextLabel

  return useMemo(
    () => (
      <Fragment>
        <span className={classes.secondaryAnswer}>{answer[0]}</span>
        <div className={classes.seperator}>{separatorTextLabel}</div>
        <span className={classes.primaryAnswer}>{answer[1]}</span>
      </Fragment>
    ),
    [answer, classes]
  )
}

export default ImplicitBiasMultipleAnswers
